import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {WithChildren} from '../../../_layouts/helpers'

type AuthContextProps = {
  checkLocatonData: any
  setCheckLocatonData: Dispatch<SetStateAction<any>>
  locationEmpCount: any
  setLocationEmpCount: Dispatch<SetStateAction<any>>
  usersData: any
  setUsersData: Dispatch<SetStateAction<any>>
  usersEditData: any
  setUsersEditData: Dispatch<SetStateAction<any>>
  usersSNameData: any
  setUsersSNameData: Dispatch<SetStateAction<any>>
  companyDataById: any
  setCompanyDataById: Dispatch<SetStateAction<any>>
  locationDataById: any
  setLocationDataById: Dispatch<SetStateAction<any>>
}

const initAuthContextPropsState = {
  checkLocatonData: undefined,
  setCheckLocatonData: () => {},
  locationEmpCount: undefined,
  setLocationEmpCount: () => {},
  usersData: undefined,
  setUsersData: () => {},
  usersEditData: undefined,
  setUsersEditData: () => {},
  usersSNameData: undefined,
  setUsersSNameData: () => {},
  companyDataById: undefined,
  setCompanyDataById: () => {},
  locationDataById: undefined,
  setLocationDataById: () => {},
}

const GlobalContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useGlobal = () => {
  return useContext(GlobalContext)
}

const GlobalProvider: FC<WithChildren> = ({children}) => {
  // const [companyId, setCompanyId] = useState<any>()
  // const [checkCompanyId, setCheckCompanyId] = useState<any>()
  const [checkLocatonData, setCheckLocatonData] = useState<any>([])
  // const [checkLocationId, setCheckLocationId] = useState<any>()
  const [locationEmpCount, setLocationEmpCount] = useState<any>({})
  const [usersEditData, setUsersEditData] = useState<any>(false)
  const [usersData, setUsersData] = useState<any>({})
  const [usersSNameData, setUsersSNameData] = useState<any>('')
  const [companyDataById, setCompanyDataById] = useState<any>({})
  const [locationDataById, setLocationDataById] = useState<any>({})

  return (
    <GlobalContext.Provider
      value={{
        checkLocatonData,
        setCheckLocatonData,
        usersData,
        setUsersData,
        locationEmpCount,
        setLocationEmpCount,
        usersEditData,
        setUsersEditData,
        usersSNameData,
        setUsersSNameData,
        companyDataById,
        setCompanyDataById,
        locationDataById,
        setLocationDataById,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export {GlobalProvider, useGlobal}
