import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_layouts/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_layouts/helpers'

type ID = undefined | null | number

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  companyId?: ID
  setCompanyId: Dispatch<SetStateAction<ID>>
  checkCompanyId: string
  setCheckCompanyId: Dispatch<SetStateAction<string>>
  checkLocationId: string
  setCheckLocationId: Dispatch<SetStateAction<string>>
  // checkLocatonData: any
  // setCheckLocatonData: Dispatch<SetStateAction<any>>
  locationData: any
  setLocationData: Dispatch<SetStateAction<any>>
  // usersData: any
  // setUsersData: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  companyId: undefined,
  setCompanyId: () => {},
  checkCompanyId: '',
  setCheckCompanyId: () => {},
  checkLocationId: '',
  setCheckLocationId: () => {},
  // checkLocatonData: undefined,
  // setCheckLocatonData: () => {},
  locationData: undefined,
  setLocationData: () => {},
  // usersData: undefined,
  // setUsersData: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [companyId, setCompanyId] = useState<any>()
  const [checkCompanyId, setCheckCompanyId] = useState<any>()
  // const [checkLocatonData, setCheckLocatonData] = useState<any>([])
  const [checkLocationId, setCheckLocationId] = useState<any>()
  const [locationData, setLocationData] = useState<any>({})
  // const [usersData, setUsersData] = useState<any>({})
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setCompanyId(undefined)
    // setUsersData(undefined)
    setLocationData(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        companyId,
        setCompanyId,
        checkCompanyId,
        setCheckCompanyId,
        checkLocationId,
        setCheckLocationId,
        // checkLocatonData,
        // setCheckLocatonData,
        locationData,
        setLocationData,
        // usersData,
        // setUsersData,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, companyId, setCompanyId} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    // const requestUser = async (apiToken: string) => {
    //   try {
    //     if (!didRequest.current) {
    //       const {data} = await getUserByToken(apiToken)
    //       if (data) {
    //         setCurrentUser(data)
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //     if (!didRequest.current) {
    //       logout()
    //     }
    //   } finally {
    //     setShowSplashScreen(false)
    //   }

    //   return () => (didRequest.current = true)
    // }

    // if (auth && auth.api_token) {
    //   requestUser(auth.api_token)
    // } else {
    //   logout()
    //   setShowSplashScreen(false)
    // }
    // eslint-disable-next-line

    // console.log('test check')

    const storedToken = JSON.parse(window.localStorage.getItem('kt-auth-react-v')!)
    if (storedToken) {
      setCurrentUser(storedToken)
      setShowSplashScreen(false)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
