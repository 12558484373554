import {FC, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_layouts/helpers'
import {useNavigate} from 'react-router-dom'
import {auto} from '@popperjs/core'

type Props = {
  resetStatus: any
}

const PasswordResetModal: FC<Props> = ({resetStatus}) => {
  const navigate = useNavigate()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-610px'>
          <div className='modal-content'>
            <div className='modal-header bg-success h-25 pt-10 b-10'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/tick.png')}
                className='tick'
                style={{width: '100px', height: '100%', margin: 'auto'}}
              />
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary '
                data-kt-users-modal-action='close'
                onClick={() => resetStatus(false)}
                style={{cursor: 'pointer', position: 'absolute', top: '0', right: '0'}}
              >
                <KTIcon iconName='cross' className='fs-1 text-white' />
              </div>
              {/* end::Close */}
            </div>
            <div className='modal-body scroll-y ms-5 me-5 my-7 '>
              <div
                className='row scroll-y'
                style={{borderBottom: '1px solid rgb(205 203 203)'}}
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='fv-row mb-8'>
                  <h1>Passward has been reset!</h1>
                  <p className='fs-5'>Please check your email.</p>
                </div>
              </div>
              {/* end::Scroll */}

              {/* begin::Actions */}
              <div className='text-center pt-15'>
                {/* <button
                  type='reset'
                  onClick={() => resetStatus(undefined)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button> */}

                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={() => {
                    navigate('/login')
                  }}
                  data-kt-users-modal-action='submit'
                >
                  <span className='indicator-label'>Ok</span>
                  {/* {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default PasswordResetModal
