import {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_layouts/helpers'
import {useGlobal} from '../global-state/Global'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useAuth} from '../auth'
import axios from 'axios'
// import {deleteLocation} from '../core/_requests'
type Props = {
  setModalVisible: any
}

const createUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  // password: Yup.string().min(5).required('Password is required'),
  confirmPassword: Yup.string()
    // .required('Confirm Password is mendatory')
    .oneOf([Yup.ref('password')], 'Password does not match'),
})

const SuperAdminEdit: FC = () => {
  const {setUsersEditData, setUsersSNameData} = useGlobal()
  const {currentUser, logout} = useAuth()
  const API_URL = process.env.REACT_APP_API_URL
  const storedToken = JSON.parse(window.localStorage.getItem('kt-auth-react-v')!)
  const sName = localStorage.getItem('sName')
  const removeId = () => {
    setUsersEditData(false)
  }

  const cancel = () => {
    setUsersEditData(false)
  }

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const initialValues = {
    name: sName ? sName : currentUser?.name,
    email: currentUser?.email,
    password: '',
    confirmPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (values.password !== values.confirmPassword) {
        setUsersEditData(false)
        return toast.error('Password not matched.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }

      setSubmitting(true)
      try {
        const res = axios.put(`${API_URL}/auth/update-sadmin`, values, {
          headers: {
            Authorization: `Bearer ${storedToken.token}`,
          },
        })
        localStorage.setItem('sName', (await res).data.data.name)
        // Retrieve data from localStorage
        const existingData = JSON.parse(localStorage.getItem('kt-auth-react-v')!)
        if (existingData && typeof existingData === 'object') {
          existingData.name = (await res).data.data.name
          localStorage.setItem('kt-auth-react-v', JSON.stringify(existingData))
        }
        setUsersEditData(false)
        toast.success('User successfully Updated.', {
          position: toast.POSITION.TOP_RIGHT,
        })
        const data = localStorage.getItem('sName')
        setUsersSNameData(data)
      } catch (ex: any) {
        console.log(ex)
        toast.error(ex.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } finally {
        setSubmitting(true)
        // cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-650px'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h2 className='fw-bolder'>Edit Profile</h2>
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => removeId()}
                  style={{cursor: 'pointer'}}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
                {/* end::Close */}
              </div>
              <div className='modal-body scroll-y ms-5 my-7'>
                <div
                  className='row scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className=' mb-8 col-md-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      Name<span className='text-danger'>*</span>
                    </label>
                    <input
                      placeholder='Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.name && formik.errors.name,
                        },
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=' mb-8 col-md-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      Email<span className='text-danger'>*</span>
                    </label>
                    <input
                      disabled
                      placeholder='Email'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.email && formik.errors.email,
                        },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=' mb-8 col-md-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      Password<span className='text-danger'>*</span>
                    </label>
                    <input
                      placeholder='password'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=' mb-8 col-md-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>
                      Confirm Password<span className='text-danger'>*</span>
                    </label>
                    <input
                      placeholder='Confirm Password'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('confirmPassword')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.confirmPassword && formik.errors.confirmPassword,
                        },
                        {
                          'is-valid':
                            formik.touched.confirmPassword && !formik.errors.confirmPassword,
                        }
                      )}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => cancel()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Cancel
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                  >
                    <span className='indicator-label'>Submit</span>
                    {/* {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )} */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-backdrop fade show'></div>
      </form>
    </>
  )
}

const UsersEdit = () => {
  const {usersEditData} = useGlobal()
  return <>{usersEditData && <SuperAdminEdit />}</>
}

export {UsersEdit}
