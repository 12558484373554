/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_layouts/helpers'
import {PageTitle} from '../../../_layouts/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_layouts/partials/widgets'
import {useAuth} from '../../modules/auth/core/Auth'
import {LocationInformation} from './LocationInformation'
import {CounAdminUser} from './CounAdminUser'
import axios from 'axios'

type Props = {
  countsData: any
}
const DashboardPage: FC<Props> = ({countsData}) => {
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <LocationInformation countsData={countsData} />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <CounAdminUser countsData={countsData} />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const API_URL = process.env.REACT_APP_API_URL
  const storedToken = JSON.parse(window.localStorage.getItem('kt-auth-react-v')!)
  const [countsData, setCountsData] = useState<any>({})
  const InformationCount = async () => {
    try {
      const res = await axios.get(`${API_URL}/company/allStats`, {
        headers: {
          Authorization: `Bearer ${storedToken.token}`,
        },
      })
      setCountsData(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    InformationCount()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage countsData={countsData} />
    </>
  )
}

export {DashboardWrapper}
