import {FC} from 'react'
import {useQuery} from 'react-query'

type Props = {
  countsData: any
}

const LocationInformation: FC<Props> = ({countsData}) => {
  return (
    <>
      <div className={`card card-flush`}>
        <div className='card-header pt-5'>
          <h3 className='card-title text-gray-800 fw-bold'>Locations Information</h3>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body pt-5'>
          <div className='d-flex flex-stack'>
            <a className='text-dark fw-semibold fs-5 me-2'>Total Locations</a>
            <p className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end w-100 mb-0 w-auto fs-5 text-gray-700'>
              {countsData?.totalLocations}
            </p>
          </div>
          <div className='separator separator-dashed my-2' />
          <div className='d-flex flex-stack'>
            <a className='text-dark fw-semibold fs-5 me-2'>Active Locations</a>
            <p className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end w-100 mb-0 w-auto fs-5 text-gray-700'>
              {countsData?.activeLocations}
            </p>
          </div>
          <div className='separator separator-dashed my-2' />
          <div className='d-flex flex-stack'>
            <a className='text-dark fw-semibold fs-5 me-2'>Inactive Locations</a>
            <p className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end w-100 mb-0 w-auto fs-5 text-gray-700'>
              {countsData?.inActiveLocations}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export {LocationInformation}
